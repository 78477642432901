import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Use abstractions instead of comments`}</h1>
    <p>{`Instead of commenting what a piece of code does, create a function with
a name that describes what it does.`}</p>
    <br />
    <p>{`The code below the comment might change without the comment, and in time,
the comment will become outdated, possibly wrong, and end up as a lie.`}</p>
    <br />
    <deckgo-highlight-code {...{
      "language": "javascript"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`// enable dragging and dropping of images
window.addEventListener('dragover', preventDefault);
window.addEventListener('dragenter', preventDefault);
window.addEventListener('drop', files => controller.readDroppedFiles(files));`}</code>{`
        `}</deckgo-highlight-code>
    <br />
Instead you should create a function with a good descriptive name.
    <deckgo-highlight-code {...{
      "language": "javascript"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`function enableDragAndDropOfImagesFiles(controller) {
  window.addEventListener('dragover', preventDefault);
  window.addEventListener('dragenter', preventDefault);
  window.addEventListener('drop', files => controller.readDroppedFiles(files));
}`}</code>{`
        `}</deckgo-highlight-code>
    <br />
Then call that function. The comment is no longer needed.
    <deckgo-highlight-code {...{
      "language": "javascript"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`enableDragAndDropOfImagesFiles(controller);`}</code>{`
        `}</deckgo-highlight-code>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      